// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/pageHeader/PageHeader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/pageHeader/PageHeader.tsx");
}
// REMIX HMR END

import clsx from 'clsx';
import styles from './PageHeader.module.css';
import { Divider } from '../Divider';
import { usePrefixedTranslation } from '~/hooks/usePrefixedTranslation';
import { Link } from '@remix-run/react';
import { PageWidth } from '../page/PageWidth';
export function PageHeader({
  className,
  as = 'header',
  children,
  divider = true,
  flourishes = true,
  coupon,
  ...props
}) {
  _s();
  const {
    t
  } = usePrefixedTranslation('common');
  const Element = as;
  return <Element className={clsx(styles.pageHeader, flourishes && styles.flourishes, className)} {...props}>
      <PageWidth>
        <Link to={coupon ? `/?coupon=${coupon}` : '/'} className={styles.logo}>
          <img src="/img/logo.svg" alt={t('subscribeToTheBodyCoach') ?? ''} />
        </Link>
      </PageWidth>
      {children}

      <div role="presentation" className={styles.bg}></div>

      {divider ? <Divider className={styles.divider} /> : null}
    </Element>;
}
_s(PageHeader, "YbpGSBOvx+UXrbc9uATeO7+5K1M=", false, function () {
  return [usePrefixedTranslation];
});
_c = PageHeader;
var _c;
$RefreshReg$(_c, "PageHeader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;