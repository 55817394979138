// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/page/Page.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/page/Page.tsx");
}
// REMIX HMR END

import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './Page.module.css';
export const Page = forwardRef(_c = function Page({
  children,
  className
}, ref) {
  return <main ref={ref} className={clsx(styles.page, className)}>
      {children}
    </main>;
});
_c2 = Page;
var _c, _c2;
$RefreshReg$(_c, "Page$forwardRef");
$RefreshReg$(_c2, "Page");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;