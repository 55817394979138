// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/page/PageWidth.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/page/PageWidth.tsx");
}
// REMIX HMR END

import clsx from 'clsx';
import styles from './PageWidth.module.css';
/**
 * Constrains `children` to a consistent maximum width that's used across all pages.
 */
export function PageWidth({
  children,
  narrow = false
}) {
  return <div className={clsx(styles.pageWidth, narrow && styles.narrow)}>{children}</div>;
}
_c = PageWidth;
var _c;
$RefreshReg$(_c, "PageWidth");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;