import Dinero, { Currency } from 'dinero.js'

/**
 * Converts a Stripe amount to a localised string based on the provided `language`
 *
 * @see https://stripe.com/docs/currencies#zero-decimal
 * @see https://dinerojs.com/module-dinero#~toRoundedUnit
 */
export function amountToLocaleString(amount: number, currency: string, language: string) {
  return Dinero({ amount, currency: currency as Currency })
    .toRoundedUnit(2)
    .toLocaleString(language, {
      style: 'currency',
      currency
    })
}
